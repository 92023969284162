import { render, staticRenderFns } from "./ManagerAgent.vue?vue&type=template&id=90f028a6&scoped=true&"
import script from "./ManagerAgent.vue?vue&type=script&lang=js&"
export * from "./ManagerAgent.vue?vue&type=script&lang=js&"
import style0 from "./ManagerAgent.vue?vue&type=style&index=0&id=90f028a6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90f028a6",
  null
  
)

export default component.exports